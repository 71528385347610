import React, { useMemo, useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';

import { TabContainer } from 'components/tabs';
import { Button } from 'components/button';

import PublishedWorkflowsTable from './publishedWorkflowsTable';
import DraftWorkflowsTable from './draftWorkflowsTable';
import AddWorkflowModal from './addWorkflowModal';
import type { AddWorkflowModalProps } from './addWorkflowModal';

import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import {
  useGetWorkflowsQuery,
  useGetWorkflowUseCasesQuery,
  WorkflowUseCaseApiData
} from 'services/api/workflowApi';
import { useAppSelector } from 'services/hook';
import { WorkflowSchemaApiData } from 'services/types/workflow';
import { WithIdTransformed } from 'services/types/common';

import _ from 'lodash';

const WorkflowsPage = () => {
  const [addWorkflowModalOpen, setAddWorkflowModalOpen] = useState(false);
  const { FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS } = useAppSelector(
    state => state.featureFlags
  );
  const { data: workflowsData } = useGetWorkflowsQuery({
    params: { include_drafts: 'True' }
  });
  const { data: useCaseData } = useGetWorkflowUseCasesQuery({});
  const { data: merchantData } = useGetDiscoverableProfilesQuery({
    params: {
      organization_type: 'merchant'
    }
  });

  const missingUseCases = useMemo(() => {
    const missingUseCases: AddWorkflowModalProps['useCaseOptions'] = [];
    if (useCaseData && workflowsData) {
      useCaseData.forEach(useCase => {
        if (!Object.hasOwn(workflowsData, useCase.value)) {
          missingUseCases.push({
            title: _.startCase(useCase.value),
            value: useCase.value,
            subtitle: useCase.description
          });
        }
      });
    }
    return missingUseCases;
  }, [Object.keys(useCaseData ?? {})]);

  const customizableUseCases = useMemo(() => {
    const newData: WorkflowUseCaseApiData[] = [];
    if (!useCaseData) return [];

    const data = useCaseData.find(useCase => {
      return useCase.metadata_options !== null;
    });
    if (data !== undefined) {
      newData.push(data);
    }
    return newData.map(el => {
      return {
        title: _.startCase(el.value),
        value: el.value,
        metadata_options: el.metadata_options
      };
    });
  }, [useCaseData]);

  const publishedWorkflows = useMemo(() => {
    const publishedWorkflowData: WithIdTransformed<WorkflowSchemaApiData> = {};
    if (workflowsData) {
      Object.entries(workflowsData).forEach(([use_case, workflow]) => {
        if (workflow.published) {
          publishedWorkflowData[use_case] = workflow;
        }
      });
    }
    return publishedWorkflowData;
  }, [Object.keys(workflowsData ?? {})]);

  const draftWorkflows = useMemo(() => {
    const draftWorkflowData: WithIdTransformed<WorkflowSchemaApiData> = {};
    if (workflowsData) {
      Object.entries(workflowsData).forEach(([use_case, workflow]) => {
        if (!workflow.published) {
          draftWorkflowData[use_case] = workflow;
        }
      });
    }
    return draftWorkflowData;
  }, [Object.keys(workflowsData ?? {})]);

  const getDescriptions = (
    tableData: WithIdTransformed<WorkflowSchemaApiData>
  ) => {
    const description: WorkflowUseCaseApiData[] = [];
    if (tableData && useCaseData) {
      Object.keys(tableData).map(el => {
        const matchedCase = useCaseData.find(item => item.value === el);
        matchedCase && description.push(matchedCase);
      });
    }
    return description;
  };

  const merchantOptions = useMemo(() => {
    return (
      (merchantData &&
        Object.values(merchantData).map(merchant => ({
          title: merchant.name,
          value: merchant.profile_id
        }))) ||
      []
    );
  }, [merchantData]);

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.75rem'
          }}
        >
          <Stack gap=".25rem" width="inherit">
            <Typography
              variant="h6Bold"
              color="#475467"
              sx={{ width: '10.75rem' }}
            >
              My workflows
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              color="#98A2B3"
              sx={{ width: '23rem' }}
            >
              See all workflows in your organization
            </Typography>
          </Stack>
          <Box sx={{ minWidth: '8rem', display: 'flex', marginLeft: 'auto' }}>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setAddWorkflowModalOpen(true);
              }}
              icon={plusIcon}
              iconPosition="start"
              text="Create Workflow"
              styleOverrides={{ padding: '0.5rem 0.75rem' }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '5rem',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <TabContainer
          tabs={[
            {
              labelText: 'Published',
              content: (
                <PublishedWorkflowsTable
                  workflows={publishedWorkflows}
                  description={getDescriptions(publishedWorkflows)}
                />
              )
            },
            {
              labelText: 'Drafts',
              content: (
                <DraftWorkflowsTable
                  workflows={draftWorkflows}
                  description={getDescriptions(draftWorkflows)}
                />
              )
            }
          ]}
        />
      </Box>
      <AddWorkflowModal
        open={addWorkflowModalOpen}
        onClose={() => setAddWorkflowModalOpen(false)}
        useCaseOptions={missingUseCases}
        customizableUseCaseOptions={customizableUseCases}
        merchantOptions={merchantOptions}
        customWorkflowFlag={FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS}
      />
    </Box>
  );
};

export default WorkflowsPage;
