import { ProductApiData } from 'services/types/product';
import { BranchApiData } from 'services/types/branches';
import { OrganizationApiData } from 'services/types/organization';
import { WorkflowApiData } from './workflow';
import { ProductReceiptStatus } from 'services/enums/product';
import { SupportedCurrency } from 'services/enums/currency';

export type ProductReceiptPickupAgentApiData = {
  email: string;
  firstname: string;
  lastname: string;
  phone_number: string;
  profile_picture: string | null;
  vehicle_make: string | null;
  vehicle_model: string | null;
  vehicle_plate_number: string | null;
  vehicle_color: string | null;
  vehicle_photo: string | null;
};

export type ProductReceiptPickupCodeApiData = {
  code: string;
  expires_on: string;
};

export type ProductReceiptPickupScheduleApiData = {
  depot: string;
  pickup_date: string;
};

export type ProductReceiptCollectionDepotApiData = {
  depot_id: string;
  name: string;
  branch_address: string;
};

export type ProductReceiptDocumentApiData = {
  name: string;
  file: string;
  owner: OrganizationApiData;
  public: string;
};

export type ProductPickupRequestApiData = {
  product_request_id: string;
  product_receipt_id: string;
  customer: OrganizationApiData;
  product: string;
  quantity: string;
  depot: ProductReceiptCollectionDepotApiData | null;
  pickup_agent: ProductReceiptPickupAgentApiData | null;
  pickup_code: ProductReceiptPickupCodeApiData | null;
  pickup_date: string | null;
  requested_on: string | null;
  scheduled_on: string | null;
};

export type ProductReceiptEndorsementApiData = {
  endorsee: OrganizationApiData;
  endorsed_on: string | null;
};

export type ProductReceiptHolderApiData = {
  public_id: string;
  name: string;
  logo: string;
  organization_type: string;
};

export type MiniProductReceiptData = {
  product_receipt_id: string;
  batch_id: string;
  total_quantity: number;
  quoted_price: number;
  quoted_price_currency: string;
  quoted_price_expiry?: string;
  status: ProductReceiptStatus;
};

export type ProductReceiptApiData = {
  product_receipt_id: string;
  batch_id: string | null;
  product: ProductApiData;
  total_quantity: number;
  quoted_price: number;
  quoted_price_currency: SupportedCurrency;
  quoted_price_expiry: string | null;
  status: ProductReceiptStatus;
  holders: ProductReceiptHolderApiData[];
  assigned_branch: BranchApiData | null;
  merchant: OrganizationApiData;
  endorsements: ProductReceiptEndorsementApiData[];
  branch_assignment_workflow: WorkflowApiData | null;
  endorsement_workflow: WorkflowApiData | null;
  pickup_request: ProductPickupRequestApiData | null;
  documents: ProductReceiptDocumentApiData[];
  created_on: string | null;
  closed_on: string | null;
  is_last_owner: boolean;
  is_merchant: boolean;
  total_holding_period: number;
  branch_holding_period: number;
  related_product_receipts: MiniProductReceiptData[];
};

export enum ProductReceiptType {
  SINGLE = 'single',
  BATCH = 'batch'
}

export type MiniProductReceiptApiData = {
  product_receipt_id: string;
  batch_id: string | null;
  merchant: { profile_id: string; organization_name: string };
  product: {
    name: string;
    merchant_product_variant_id: string;
    unit: string;
  };
  total_quantity: number;
  quoted_price: number;
  quoted_price_currency: SupportedCurrency;
  quoted_price_expiry: string | null;
  status: ProductReceiptStatus;
  holders: string[];
  created_on: string | null;
  related_product_receipts: MiniProductReceiptData[];
};
